import React, { useEffect, useRef } from 'react';
import { Container, Box, Fade, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';  // Ensure this line is present and correct
import CallToAction from '../components/CallToAction';
import VideoSection from '../components/VideoSection';
import Footer from '../components/Footer';
import Information1 from '../components/Information1';
import Specialization from '../components/Specialization'; // Add this line

// Update these import statements
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

// Create a theme with the custom fonts
const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Open Sans, sans-serif',
    },
    body2: {
      fontFamily: 'Open Sans, sans-serif',
    },
  },
  palette: {
    primary: {
      main: '#4A8AB5', // Your existing blue color
    },
    secondary: {
      main: '#FFA500', // An example secondary color (orange)
    },
  },
});

function HomePage() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    for (let i = 0; i < 50; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5
      });
    }


    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.3)'; // Slightly transparent white
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();

        particle.x += particle.dx;
        particle.y += particle.dy;

        if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;
      });

      animationFrameId = requestAnimationFrame(drawParticles);
    };

    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          position: 'relative',
          background: '#4A8AB5',
          overflow: 'hidden',
          paddingBottom: '2rem', // Add some padding at the bottom of the hero section
        }}>
          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
            }}
          />
          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <Header />
            <Container maxWidth="lg">
              <Fade in={true} timeout={1000}>
                <Box sx={{ 
                  my: 4, // Reduced from my: 8
                  textAlign: 'center',
                  color: '#ffffff',
                }}>
                  <Typography variant="h2" component="h1" gutterBottom>
                    Filtration without Frustration
                  </Typography>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Quality drinking water for you and your family.
                  </Typography>
                </Box>
              </Fade>
            </Container>
          </Box>
        </Box>
        <Box sx={{
          position: 'relative',
          width: '100%',
          height: '5vw', // Reduced from 10vw
          backgroundColor: '#FFFFFF',
        }}>
          <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <path
              d="M0 0 L50 100 L100 0 Z"
              fill="#4A8AB5"
            />
          </svg>
        </Box>
        <Box sx={{ 
          background: '#FFFFFF', 
          position: 'relative', 
          zIndex: 2,
          marginTop: '-1px', // Ensure no gap between divider and content
        }}>
          <Container maxWidth="xl"> {/* Changed from "lg" to "xl" */}
            <Fade in={true} timeout={1000} style={{ transitionDelay: '500ms' }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Information1 />
                <Specialization />
              </Box>
            </Fade>
          </Container>
          <VideoSection />
          <Box sx={{ position: 'relative', zIndex: 3 }}> {/* Add this wrapper */}
            <CallToAction />
          </Box>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default HomePage;
