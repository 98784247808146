import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Stepper, 
  Step, 
  StepLabel, 
  Typography, 
  Box, 
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  Paper,
  useTheme,
  Grid,
  FormGroup,
  Checkbox,
  useMediaQuery,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

const steps = ['Select Services', 'Basic Info', 'Property Details', 'Schedule Appointment', 'Contact Info'];

const packages = [
  { id: 1, name: 'Basic Filtration', price: 115 },
  { id: 2, name: 'Essential Filtration', price: 235 },
  { id: 3, name: 'Advanced Filtration', price: 685 },
  { id: 4, name: 'Elite Filtration', price: 885 },
];

const addons = [
  { id: 1, name: 'Before and After Water QA Lab Testing', price: 240 },
  { id: 2, name: 'UV Filtration', price: 90 },
  { id: 3, name: 'Remineralization Attachment', price: 125 },
  { id: 4, name: 'Bathroom Sink Basic Filter', price: 75 },
];

const MobileStepper = ({ steps, activeStep }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ position: 'relative', mb: 4 }}>
      <Stepper 
        activeStep={activeStep} 
        alternativeLabel
        sx={{
          '& .MuiStepLabel-label': {
            display: { xs: 'none', sm: 'block' }
          },
          '& .MuiStepIcon-root': {
            fontSize: '1.5rem',
            '&.Mui-completed': {
              color: '#0c4a6e',  // Darker blue for completed steps
            },
            '&.Mui-active': {
              background: '#fff',  // White circle background
              borderRadius: '50%',
              padding: '2px',
              border: '2px solid #0ea5e9',  // Light blue border
              color: '#0ea5e9',
            }
          }
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' } }}>
                {index === activeStep ? label : ''}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography 
        variant="body2" 
        sx={{ 
          textAlign: 'center', 
          mt: 1,
          color: '#0c4a6e',  // Changed to darker blue to match completed steps
          fontWeight: 500
        }}
      >
        Step {activeStep + 1} of {steps.length}: {steps[activeStep]}
      </Typography>
    </Box>
  );
};

function QuoteForm() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    zipCode: '',
    waterSource: '',
    selectedPackage: null,
    selectedAddons: [],
    propertyType: '',
    numberOfBathrooms: '',
    name: '',
    email: '',
    phone: '',
    appointmentDate: '',
    appointmentTime: '',
    message: '',
  });

  const [promoCode, setPromoCode] = useState('');
  const [validPromoCode, setValidPromoCode] = useState(false);

  const [searchParams] = useSearchParams();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showAddons, setShowAddons] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const packageId = parseInt(searchParams.get('package'));
    if (packageId && packageId >= 1 && packageId <= 4) {
      setFormData(prevData => ({
        ...prevData,
        selectedPackage: packageId
      }));
    }
  }, [searchParams]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const togglePackage = (packageId) => {
    setFormData(prevData => ({
      ...prevData,
      selectedPackage: packageId
    }));
  };

  const toggleAddon = (addonId) => {
    setFormData(prevData => ({
      ...prevData,
      selectedAddons: prevData.selectedAddons.includes(addonId)
        ? prevData.selectedAddons.filter(id => id !== addonId)
        : [...prevData.selectedAddons, addonId]
    }));
  };

  const calculateTotal = () => {
    const packagePrice = packages.find(pkg => pkg.id === formData.selectedPackage)?.price || 0;
    const addonsPrice = addons
      .filter(addon => formData.selectedAddons.includes(addon.id))
      .reduce((sum, addon) => sum + addon.price, 0);
    const subtotal = packagePrice + addonsPrice;
    
    if (validPromoCode) {
      return subtotal * 0.95;
    }
    return subtotal;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (activeStep === steps.length - 1) {
      // Prepare the final data
      const finalData = {
        ...formData,
        packageName: packages.find(pkg => pkg.id === formData.selectedPackage)?.name,
        addons: addons
          .filter(addon => formData.selectedAddons.includes(addon.id))
          .map(addon => addon.name)
          .join(', '),
        subtotal: calculateTotal() / (validPromoCode ? 0.95 : 1),
        total: calculateTotal(),
        promoApplied: validPromoCode ? 'Yes (5% off)' : 'No',
      };

      try {
        // Submit to Netlify forms
        await fetch('/', {
          method: 'POST',
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            'form-name': 'quote-request',
            ...finalData
          }).toString()
        });
        
        // Navigate to thank you page after successful submission
        navigate('/thank-you', { 
          state: { 
            formData: finalData,
            formType: 'quote'
          } 
        });
      } catch (error) {
        console.error('Form submission error:', error);
        alert('There was an error submitting your quote request. Please try again.');
      }
    } else {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const applyPromoCode = () => {
    if (promoCode.toUpperCase() === 'MWP5') {
      setValidPromoCode(true);
      alert('Promo code applied successfully! 5% discount added.');
    } else {
      setValidPromoCode(false);
      alert('Invalid promo code.');
    }
    setPromoCode('');
  };

  const renderSummary = () => {
    const subtotal = calculateTotal() / (validPromoCode ? 0.95 : 1);
    const savings = validPromoCode ? subtotal * 0.05 : 0;
    
    return (
      <Box sx={{ p: 2, backgroundColor: theme.palette.background.paper, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Your Quote Summary
        </Typography>
        <List>
          {formData.selectedPackage && (
            <ListItem>
              <Typography variant="body1">
                Selected Package: {packages.find(pkg => pkg.id === formData.selectedPackage).name}
              </Typography>
            </ListItem>
          )}
          {formData.selectedAddons.length > 0 && (
            <ListItem>
              <Typography variant="body1">
                Add-ons: {formData.selectedAddons.map(id => addons.find(addon => addon.id === id).name).join(', ')}
              </Typography>
            </ListItem>
          )}
          {formData.zipCode && (
            <ListItem>
              <Typography variant="body1">Zip Code: {formData.zipCode}</Typography>
            </ListItem>
          )}
          {formData.waterSource && (
            <ListItem>
              <Typography variant="body1">Water Source: {formData.waterSource}</Typography>
            </ListItem>
          )}
          {formData.propertyType && (
            <ListItem>
              <Typography variant="body1">Property Type: {formData.propertyType}</Typography>
            </ListItem>
          )}
          {formData.numberOfBathrooms && (
            <ListItem>
              <Typography variant="body1">Number of Bathrooms: {formData.numberOfBathrooms}</Typography>
            </ListItem>
          )}
          {formData.appointmentDate && formData.appointmentTime && (
            <ListItem>
              <Typography variant="body1">
                Appointment: {formData.appointmentDate}, {formData.appointmentTime === '9-12' ? '9:00 AM - 12:00 PM' : '1:00 PM - 3:00 PM'}
              </Typography>
            </ListItem>
          )}
        </List>
        <Box sx={{ mt: 3, p: 2, backgroundColor: theme.palette.background.default, borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Estimated Total
          </Typography>
          {validPromoCode && (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                ${subtotal.toFixed(2)}
              </Typography>
              <Typography variant="body2" color="success.main" gutterBottom>
                5% discount applied (${savings.toFixed(2)})
              </Typography>
            </>
          )}
          <Typography variant="h5" color="text.primary">
            ${calculateTotal().toFixed(2)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderPromoCode = () => {
    return (
      <Box sx={{ mt: 2, p: 2, backgroundColor: theme.palette.background.paper, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Promo Code
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            size="small"
            label="Enter promo code"
            value={promoCode}
            onChange={handlePromoCodeChange}
            sx={{ mr: 1, backgroundColor: 'white' }}
          />
          <Button
            variant="contained"
            onClick={applyPromoCode}
            sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    );
  };

  const renderMobileSummary = () => {
    const subtotal = calculateTotal() / (validPromoCode ? 0.95 : 1);
    const savings = validPromoCode ? subtotal * 0.05 : 0;
    const total = calculateTotal();
    const selectedPackage = packages.find(pkg => pkg.id === formData.selectedPackage);
    const selectedAddonsList = addons.filter(addon => formData.selectedAddons.includes(addon.id));
    
    return (
      <Box sx={{ mb: 3, p: 2, backgroundColor: '#ffffff', borderRadius: 2 }}>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          Quote Summary
        </Typography>
        {selectedPackage && (
          <Typography variant="body2">
            {selectedPackage.name}
          </Typography>
        )}
        {formData.selectedAddons.length > 0 && (
          <>
            <Box
              onClick={() => setShowAddons(!showAddons)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.main,
                }
              }}
            >
              {showAddons ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              <Typography variant="body2">
                {formData.selectedAddons.length} add-on{formData.selectedAddons.length > 1 ? 's' : ''} selected
              </Typography>
            </Box>
            {showAddons && (
              <Box sx={{ ml: 4, mt: 1 }}>
                {selectedAddonsList.map(addon => (
                  <Typography key={addon.id} variant="body2" color="text.secondary">
                    • {addon.name} (${addon.price})
                  </Typography>
                ))}
              </Box>
            )}
          </>
        )}
        <Box sx={{ mt: 2 }}>
          {validPromoCode && (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                ${subtotal.toFixed(2)}
              </Typography>
              <Typography variant="body2" color="success.main">
                5% discount applied (-${savings.toFixed(2)})
              </Typography>
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
            <Typography variant="subtitle1">Total:</Typography>
            <Typography variant="h6" color="primary">
              ${total.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          {showPromoInput ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                size="small"
                label="Promo code"
                value={promoCode}
                onChange={handlePromoCodeChange}
                sx={{ flex: 1, backgroundColor: 'white' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  applyPromoCode();
                  setShowPromoInput(false);
                }}
                size="small"
              >
                Apply
              </Button>
            </Box>
          ) : (
            <Button
              variant="text"
              onClick={() => setShowPromoInput(true)}
              sx={{ 
                p: 0,
                textTransform: 'none',
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline'
                }
              }}
            >
              Enter promo code
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              Select Your Filtration Package
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
              Need help choosing? Check our <a href="/packages" target="_blank" rel="noopener noreferrer">packages page</a> for detailed information.
            </Typography>
            <Grid container spacing={2}>
              {packages.map((pkg) => (
                <Grid item xs={12} sm={6} key={pkg.id}>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      p: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      textAlign: 'left',
                      borderColor: formData.selectedPackage === pkg.id 
                        ? theme.palette.primary.main 
                        : theme.palette.grey[300],
                      backgroundColor: formData.selectedPackage === pkg.id 
                        ? theme.palette.primary.main
                        : 'transparent',
                      color: formData.selectedPackage === pkg.id
                        ? '#ffffff'
                        : theme.palette.text.primary,
                      '&:hover': {
                        backgroundColor: formData.selectedPackage === pkg.id
                          ? theme.palette.primary.dark
                          : theme.palette.action.hover,
                      },
                    }}
                    onClick={() => togglePackage(pkg.id)}
                  >
                    <Typography 
                      variant="subtitle1" 
                      component="div"
                      sx={{ 
                        color: formData.selectedPackage === pkg.id ? '#ffffff' : 'inherit'
                      }}
                    >
                      {pkg.name}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        mt: 1,
                        color: formData.selectedPackage === pkg.id ? '#ffffff' : theme.palette.primary.main
                      }}
                    >
                      ${pkg.price}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Add-on Options (Optional)
            </Typography>
            <FormGroup>
              {addons.map((addon) => (
                <FormControlLabel
                  key={addon.id}
                  control={
                    <Checkbox
                      checked={formData.selectedAddons.includes(addon.id)}
                      onChange={() => toggleAddon(addon.id)}
                    />
                  }
                  label={`${addon.name} - $${addon.price}`}
                />
              ))}
            </FormGroup>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ py: 2 }}>
            <TextField
              fullWidth
              label="Zip Code"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              margin="normal"
              required
              sx={{ 
                backgroundColor: 'white',
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.grey[300],
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Water Source</FormLabel>
              <RadioGroup
                name="waterSource"
                value={formData.waterSource}
                onChange={handleChange}
              >
                <FormControlLabel value="city" control={<Radio />} label="City Water" />
                <FormControlLabel value="well" control={<Radio />} label="Well Water" />
              </RadioGroup>
            </FormControl>
          </Box>
        );
      case 2:
        return (
          <Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Property Type</InputLabel>
              <Select
                name="propertyType"
                value={formData.propertyType}
                onChange={handleChange}
                sx={{ backgroundColor: 'white' }}
              >
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
                <MenuItem value="business">Business</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Number of Bathrooms"
              name="numberOfBathrooms"
              type="number"
              value={formData.numberOfBathrooms}
              onChange={handleChange}
              margin="normal"
              sx={{ backgroundColor: 'white' }}
            />
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Installation Day Preference
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Let us know what day works best for you. We'll confirm the exact calendar date over email.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <Box component="span" fontWeight="bold">Current lead time:</Box> 5 days
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Preferred Day</InputLabel>
              <Select
                name="appointmentDate"
                value={formData.appointmentDate}
                onChange={handleChange}
                sx={{ backgroundColor: 'white' }}
              >
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                  <MenuItem key={day} value={day}>{day}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Preferred Time</InputLabel>
              <Select
                name="appointmentTime"
                value={formData.appointmentTime}
                onChange={handleChange}
                sx={{ backgroundColor: 'white' }}
              >
                <MenuItem value="9-12">9:00 AM - 12:00 PM</MenuItem>
                <MenuItem value="1-3">1:00 PM - 3:00 PM</MenuItem>
              </Select>
            </FormControl>
          </Box>
        );
      case 4:
        return (
          <Box>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
              sx={{ backgroundColor: 'white' }}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              sx={{ backgroundColor: 'white' }}
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              required
              sx={{ backgroundColor: 'white' }}
            />
            <TextField
              fullWidth
              label="Additional Information"
              name="message"
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              placeholder="Let us know if you have any specific concerns, questions, or special requirements for your installation."
              sx={{ 
                backgroundColor: 'white',
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '0.875rem',
                  opacity: 0.7,
                }
              }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {isMobile ? (
        <MobileStepper steps={steps} activeStep={activeStep} />
      ) : (
        <Stepper 
          activeStep={activeStep} 
          alternativeLabel 
          sx={{ 
            mb: 4,
            '& .MuiStepLabel-label': {
              mt: 1,
            },
            '& .MuiStepIcon-root': {
              '&.Mui-completed': {
                color: '#0c4a6e',  // Darker blue for completed steps
              },
              '&.Mui-active': {
                background: '#fff',  // White circle background
                borderRadius: '50%',
                padding: '2px',
                border: '2px solid #0ea5e9',  // Light blue border
                color: '#0ea5e9',
              }
            }
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      
      {isMobile && formData.selectedPackage && (
        renderMobileSummary()
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              backgroundColor: '#ffffff',  // Changed from #f8f9fa to white
              borderRadius: 2 
            }}
          >
            <form
              onSubmit={handleSubmit}
              name="quote-request"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              style={{ width: '100%' }}
            >
              <input type="hidden" name="form-name" value="quote-request" />
              <p hidden>
                <label>
                  Don't fill this out: <input name="bot-field" />
                </label>
              </p>
              
              {renderStepContent(activeStep)}
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mt: 4,
                pt: 3,
                borderTop: `1px solid ${theme.palette.grey[200]}`
              }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ 
                    color: theme.palette.text.primary,
                    px: 4
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    px: 4,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    }
                  }}
                  disabled={activeStep === 0 && !formData.selectedPackage}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
        
        {!isMobile && (
          <Grid item xs={12} md={4}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                backgroundColor: '#ffffff',  // Changed from #f8f9fa to white
                borderRadius: 2, 
                mb: 3 
              }}
            >
              {renderSummary()}
            </Paper>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                backgroundColor: '#ffffff',  // Changed from #f8f9fa to white
                borderRadius: 2 
              }}
            >
              {renderPromoCode()}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default QuoteForm;