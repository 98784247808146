import React from 'react';
import { Container, Typography, Box, useTheme, Paper, Grid, Divider } from '@mui/material';
import OpacityIcon from '@mui/icons-material/Opacity';
import Process2 from '../components/Process2';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChooseChatBot from '../components/ChooseChatBot';
import Ready from '../components/Ready';
import FilterPackages from '../components/FilterPackages';
import Installation from '../components/Installation';

function ProcessPage() {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          background: '#f8f9fa',  // Light background color
          pt: 10,
          pb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: 8,
            }}
          >
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.primary.main,
                mb: 2,
              }}
            >
              Simplifying home water filtration.
            </Typography>
            <Typography 
              variant="subtitle1" 
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '600px',
                margin: '0 auto',
                lineHeight: 1.6,
              }}
            >
              Transforming tap water into pure, refreshing hydration for your home
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
        <Box sx={{ mb: 6 }}>
          <Process2 />
        </Box>

        <Divider sx={{ my: 6 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Box sx={{ mb: 4 }}>
              <OpacityIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
              <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                Figure out what filter you need.
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, mb: 3 }}>
                Choose a starting point to discover the perfect solution tailored to your needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper 
              elevation={6} 
              sx={{ 
                p: 4, 
                borderRadius: 3,
                background: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
              }}
            >
              <Typography variant="h5" component="h3" gutterBottom sx={{ color: 'white', mb: 3 }}>
                Let's choose a filter that's right for your household. Choose a starting point below.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <ChooseChatBot />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Divider sx={{ my: 6 }} />

        {/* <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 500, mb: 4, color: theme.palette.primary.main }}>
          Find Your Perfect Filter
        </Typography> */}
        <FilterPackages />

        <Divider sx={{ my: 6 }} />

        <Installation />

        <Box sx={{ mt: 6 }}>
          <Ready />
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default ProcessPage;