import React from 'react';
import { Container, Typography, Box, useTheme, Paper, Grid, Divider } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChooseChatBot from '../components/ChooseChatBot';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function ChooseYourSystem() {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          backgroundColor: theme.palette.background.default,
          pt: 6,
          pb: 15,
        }}
      >
        <Container maxWidth="lg">
          {/* Title and Info Section */}
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Water Filtration System Finder
            </Typography>
            <Typography variant="h5" sx={{ mb: 3, color: theme.palette.text.secondary }}>
              Discover the perfect water filtration solution for your home or business
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="body1" sx={{ maxWidth: '800px', margin: '0 auto' }}>
              Our intelligent system will guide you through a series of questions to understand your specific needs and recommend the most suitable water filtration system. Whether you're looking for whole-house filtration, point-of-use solutions, or commercial systems, we've got you covered.
            </Typography>
          </Box>

          {/* Main Content */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Box sx={{ mb: 4 }}>
                <WaterDropIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
                <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Find figure out what filter you need.
                </Typography>
                <Typography variant="h6" sx={{ color: theme.palette.text.secondary, mb: 3 }}>
                  Choose a starting point to discover the perfect solution tailored to your needs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Paper 
                elevation={6} 
                sx={{ 
                  p: 4, 
                  borderRadius: 3,
                  background: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                }}
              >
                <Typography variant="h5" component="h3" gutterBottom sx={{ color: 'white', mb: 3 }}>
                    Let's choose a filter that's right for your household. Choose a starting point below.
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <ChooseChatBot />
                </Box>
              </Paper>
              {/* <Zipcode can be aded here/> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default ChooseYourSystem;