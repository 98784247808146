import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Dialog, Chip, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

const VideoSection = () => {
  const [openVideo, setOpenVideo] = useState(null);
  const theme = useTheme();
  const isStacked = useMediaQuery(theme.breakpoints.down('md'));

  const videos = [
    {
      id: '4FRy2bWjGMw',
      title: 'Understanding Water Quality',
      subtext: 'Learn about the importance of clean water',
      thumbnail: 'https://img.youtube.com/vi/4FRy2bWjGMw/maxresdefault.jpg',
    },
    {
      id: 'T-OC980OXIg',
      title: 'Water Treatment Process',
      subtext: 'Discover how we purify your water',
      thumbnail: 'https://img.youtube.com/vi/T-OC980OXIg/maxresdefault.jpg',
    },
    {
      id: 'IJ_CvgT47bI',
      title: 'Benefits of Filtered Water',
      subtext: 'Why filtered water is essential for your health',
      thumbnail: 'https://img.youtube.com/vi/IJ_CvgT47bI/maxresdefault.jpg',
    },
  ];

  const displayedVideos = isStacked ? videos.slice(0, 2) : videos;

  const handleVideoClick = (videoId) => {
    setOpenVideo(videoId);
  };

  const handleClose = () => {
    setOpenVideo(null);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography 
        variant="h3" 
        component="h2" 
        gutterBottom 
        align="center" 
        sx={{ 
          mb: 2, 
          fontWeight: 500, 
          color: '#333333',
          fontSize: { xs: '2.2rem', md: '3rem' }  // Smaller font on mobile
        }}
      >
        Don't just take it from us.
      </Typography>
      <Typography 
        variant="h5" 
        component="h3" 
        gutterBottom 
        align="center" 
        sx={{ 
          mb: 6, 
          fontWeight: 400, 
          color: '#555555',
          fontSize: { xs: '1.2rem', md: '1.5rem' }  // Smaller font on mobile
        }}
      >
        Health experts agree, filtering your drinking water is a critical investment for your health and home.
      </Typography>
      <Grid container spacing={4}>
        {displayedVideos.map((video, index) => (
          <Grid 
            item 
            key={video.id} 
            xs={12} 
            sm={6} 
            md={4}
            sx={{
              px: { xs: 4, sm: 2 }  // More padding on mobile
            }}
          >
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: 3,
                },
                cursor: 'pointer',
              }}
              onClick={() => handleVideoClick(video.id)}
            >
              <CardMedia
                component="div"
                sx={{
                  pt: '56.25%',
                  position: 'relative',
                }}
                image={video.thumbnail}
              >
                <PlayArrowIcon 
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: 60,
                    color: 'white',
                    opacity: 0.8,
                  }}
                />
              </CardMedia>
              <CardContent sx={{ 
                flexGrow: 1,
                px: { xs: 3, sm: 4 },  // Increased horizontal padding
                textAlign: 'center'     // Center all text content
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  mb: 1,
                  alignItems: 'center'  // Center the Box contents including the Chip
                }}>
                  <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="h3" 
                    sx={{ 
                      fontWeight: 500,
                      textAlign: 'center'  // Ensure title is centered
                    }}
                  >
                    {video.title}
                  </Typography>
                  {index === 0 && (
                    <Chip
                      icon={<LocalFireDepartmentIcon />}
                      label="Most Watched"
                      color="secondary"
                      size="medium"
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.9rem',
                        py: 0.5,
                        mb: 1,
                        // Removed alignSelf since parent Box is handling alignment
                      }}
                    />
                  )}
                </Box>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ textAlign: 'center' }}  // Center the subtext
                >
                  {video.subtext}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={!!openVideo}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        {openVideo && (
          <iframe
            width="100%"
            height="500"
            src={`https://www.youtube.com/embed/${openVideo}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </Dialog>
    </Container>
  );
};

export default VideoSection;
