import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, TextField, IconButton, Paper, Typography, CircularProgress, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Initialize the conversation with the new greeting from the bot
    const initialMessage = {
      text: "Ready to answer your water questions!",
      sender: 'bot'
    };
    setMessages([initialMessage]);
  }, []);

  const sendMessage = async (e) => {
    if (e) e.preventDefault();
    if (input.trim() === '') return;

    const newMessage = { text: input, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('http://localhost:3001/api/chat', { 
        message: input,
        context: messages // Send the entire conversation history
      });
      const botMessage = { text: response.data.message, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { text: 'Sorry, I encountered an error. Please try again.', sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      // Refocus the input field after sending a message
      inputRef.current?.focus();
    }
  };

  // Focus the input field when the component mounts
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '300px', // Reduced from 400px to 300px
      width: '100%',
      maxWidth: '600px',
      margin: 'auto',
      bgcolor: 'background.paper',
      borderRadius: 2,
      overflow: 'hidden',
      boxShadow: 3,
    }}>
      <Paper elevation={0} sx={{ flex: 1, overflowY: 'auto', p: 2, mb: 2 }} ref={chatContainerRef}>
        {messages.map((message, index) => (
          <Box key={index} sx={{ 
            display: 'flex', 
            justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
            mb: 2 
          }}>
            {message.sender === 'bot' && (
              <Avatar sx={{ bgcolor: 'primary.main', mr: 1 }}>
                <WaterDropIcon />
              </Avatar>
            )}
            <Paper
              elevation={1}
              sx={{
                p: 2,
                maxWidth: '70%',
                borderRadius: 2,
                bgcolor: message.sender === 'user' ? 'primary.light' : 'white',
              }}
            >
              <Typography variant="body1" color={message.sender === 'user' ? 'primary.contrastText' : 'text.primary'}>
                {message.text}
              </Typography>
            </Paper>
            {message.sender === 'user' && (
              <Avatar sx={{ bgcolor: 'secondary.main', ml: 1 }}>
                {/* You can replace this with a user icon if preferred */}
                U
              </Avatar>
            )}
          </Box>
        ))}
        <div ref={chatContainerRef} style={{ height: '1px', marginBottom: '-1px' }} />
      </Paper>
      <Paper 
        component="form" 
        onSubmit={sendMessage}
        sx={{ 
          p: '2px 4px', 
          display: 'flex', 
          alignItems: 'center', 
          borderRadius: '0 0 16px 16px',
          boxShadow: 'none',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask about water quality, filtration systems, or maintenance..."
          InputProps={{
            disableUnderline: true,
          }}
          sx={{ ml: 1, flex: 1 }}
          disabled={isLoading}
          inputRef={inputRef}
        />
        <IconButton type="submit" color="primary" sx={{ p: '10px' }} aria-label="send" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
      </Paper>
    </Box>
  );
}

export default ChatBot;