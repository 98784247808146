import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WaterQuality from '../components/WaterQuality';
import CallToAction1 from '../components/CallToAction1';

const TestYourWater = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1, py: 8, backgroundColor: '#e6f7ff' }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            mb: 6, 
            textAlign: 'center',
          }}>
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              sx={{ 
                fontWeight: 500,
                color: '#0277bd',
                position: 'relative',
                display: 'inline-block',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-10px',
                  left: '10%',
                  width: '80%',
                  height: '4px',
                  background: 'linear-gradient(90deg, #0277bd, #03a9f4)',
                }
              }}
            >
              You Are What You Drink
            </Typography>
            <Typography 
              variant="h5" 
              sx={{
                color: '#555555',
                maxWidth: '800px',
                margin: '2rem auto 0',
                lineHeight: 1.6,
                fontWeight: 400,
              }}
            >
              Upgrading your water quality is one of the best improvements for your home and health
            </Typography>
          </Box>

          <Typography variant="body1" align="center" sx={{ mb: 6, fontSize: '1.1rem', color: '#333333' }}>
            The contaminants allowed in tap water are concerning. It's up to you to make it clean and safe.
          </Typography>

          <WaterQuality />
          <CallToAction1 />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default TestYourWater;
