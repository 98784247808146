import React from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  useTheme,
  Button,
  Container
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Header from '../components/Header';
import Footer from '../components/Footer';

function ThankYou() {
  const theme = useTheme();
  const location = useLocation();
  const { formData, formType } = location.state || {};

  const renderQuoteSummary = () => (
    <>
      <Typography variant="h4" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        We've received your quote request and will contact you shortly.
      </Typography>

      <Paper elevation={1} sx={{ p: 3, backgroundColor: '#f8f8f8', textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom color="primary">
          Your Quote Summary
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>Package:</strong> {formData.packageName}
            </Typography>
          </ListItem>
          {formData.addons && (
            <ListItem>
              <Typography variant="body1">
                <strong>Add-ons:</strong> {formData.addons || 'None'}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2
            }}>
              <Typography variant="body1">
                <strong>Total Estimate:</strong>
              </Typography>
              {formData.promoApplied === 'Yes (5% off)' ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography 
                    variant="body1" 
                    sx={{ textDecoration: 'line-through', color: 'text.secondary' }}
                  >
                    ${Number(formData.subtotal).toFixed(2)}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="success.main"
                    sx={{ fontWeight: 'bold' }}
                  >
                    ${Number(formData.total).toFixed(2)}
                    <Typography 
                      component="span" 
                      variant="body2" 
                      color="success.main" 
                      sx={{ ml: 1 }}
                    >
                      (Save ${(formData.subtotal - formData.total).toFixed(2)})
                    </Typography>
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1">
                  ${Number(formData.total).toFixed(2)}
                </Typography>
              )}
            </Box>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>Appointment:</strong> {formData.appointmentDate}, {
                formData.appointmentTime === '9-12' 
                  ? '9:00 AM - 12:00 PM' 
                  : '1:00 PM - 3:00 PM'
              }
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>Contact Information:</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <Box>
              <Typography variant="body1">{formData.name}</Typography>
              <Typography variant="body1">{formData.email}</Typography>
              <Typography variant="body1">{formData.phone}</Typography>
              <Typography variant="body1">{formData.zipCode}</Typography>
            </Box>
          </ListItem>
          {formData.message && (
            <ListItem>
              <Typography variant="body1">
                <strong>Additional Information:</strong><br />
                {formData.message}
              </Typography>
            </ListItem>
          )}
        </List>
      </Paper>
    </>
  );

  const renderContactSummary = () => (
    <>
      <Typography variant="h4" gutterBottom>
        Thank You for Contacting Us!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        We've received your message and will get back to you as soon as possible.
      </Typography>

      <Paper elevation={1} sx={{ p: 3, backgroundColor: '#f8f8f8', textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom color="primary">
          Message Details
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>Name:</strong> {formData.name}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>Email:</strong> {formData.email}
            </Typography>
          </ListItem>
          {formData.phone && (
            <ListItem>
              <Typography variant="body1">
                <strong>Phone:</strong> {formData.phone}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Typography variant="body1">
              <strong>Message:</strong><br />
              {formData.message}
            </Typography>
          </ListItem>
        </List>
      </Paper>
    </>
  );

  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Box sx={{ 
          maxWidth: '800px', 
          margin: '40px auto',
          padding: { xs: 2, sm: 3 },
        }}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <CheckCircleOutlineIcon 
              sx={{ 
                fontSize: 64, 
                color: theme.palette.success.main,
                mb: 2
              }} 
            />
            
            {formType === 'contact' ? renderContactSummary() : renderQuoteSummary()}

            <Button 
              variant="contained" 
              href="/"
              sx={{ mt: 4 }}
            >
              Return to Home
            </Button>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default ThankYou; 