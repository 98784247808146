import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  const quickLinks = [
    { name: 'About', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'Contact', href: '/contact' },
    { name: 'FAQ', href: '/faq' },
  ];

  return (
    <Box component="footer" sx={{ bgcolor: '#f5f5f5', color: 'text.primary', py: 6, mt: 'auto' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Marin Water Pros, Inc.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Quality water solutions since 2023.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <IconButton color="inherit" aria-label="Facebook" size="small">
                <FacebookIcon fontSize="small" />
              </IconButton>
              <IconButton color="inherit" aria-label="Twitter" size="small">
                <TwitterIcon fontSize="small" />
              </IconButton>
              <IconButton color="inherit" aria-label="Instagram" size="small">
                <InstagramIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            {quickLinks.map((link) => (
              <Link key={link.name} component={RouterLink} to={link.href} color="inherit" display="block">
                {link.name}
              </Link>
            ))}
          </Grid>
          <Grid item xs={6} sm={3} md={4}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Link component={RouterLink} to="/contact" color="inherit" display="block">Contact</Link>
            <Link color="inherit" href="#" display="block">Privacy Policy</Link>
            <Link color="inherit" href="#" display="block">Terms of Service</Link>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Marin Water Pros. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;