import React, { useState } from 'react';
import { Box, Typography, Button, Card, CardContent, CardActions, List, ListItem, 
         ListItemIcon, ListItemText, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';

const packages = [
  {
    title: "Basic Filtration",
    price: "$115",
    features: [
      "Baseline filtration",
      "Removes chlorine and sediment",
      "Stainless Steel filter housings",
      "In-line with existing faucet",
      "Filter replacement alert system",
      "Great addition to bathroom sinks"
    ]
  },
  {
    title: "Essential Filtration",
    price: "$235",
    features: [
      "3 stage filtration",
      "99.99% of Contaminants Larger Than 0.01μm",
      "No impact to existing water pressure",
      "Removes chlorine and sediment",
      "Reduces 95% of PFAS",
      "Retains mineral content"
    ]
  },
  {
    title: "Advanced Filtration",
    price: "$685",
    features: [
      "3-Stage, carbon filtration",
      "Targets chlorine, fluoride, lead & PFAS",
      "Removes up to 99.99% of 232+ contaminants",
      "Available as in-line or dedicated faucet",
      "Low maintenance cost with 1+ year filter life"
    ]
  },
  {
    title: "Elite Filtration",
    price: "$885",
    features: [
      "State-of-the-Art Tankless Reverse Osmosis",
      "9-layer, 0.0001 filtration precision",
      "Removes 1000+ harmful contaminants",
      "Pressurized for instant flow RO",
      "Dedicated faucet included",
      "Remineralization system included"
    ]
  }
];

function FilterPackages() {
  const [currentPackage, setCurrentPackage] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const nextPackage = () => {
    setCurrentPackage((prev) => (prev + 1) % packages.length);
  };

  const prevPackage = () => {
    setCurrentPackage((prev) => (prev - 1 + packages.length) % packages.length);
  };

  const handleComparePackages = () => {
    navigate('/packages');
  };

  const handleChoosePlan = () => {
    navigate(`/get-a-quote?package=${currentPackage + 1}`);
  };

  const desktopText = (
    <>
      <Typography variant="body1" paragraph>
        Selecting the right water filtration package is crucial for ensuring the best quality water for your home. Our packages are designed to cater to different needs and budgets.
      </Typography>
      <Typography variant="body1" paragraph>
        Consider factors such as your water source, specific contaminants you want to remove, and your household's water consumption when making your choice.
      </Typography>
      <Typography variant="body1">
        If you're unsure which package is right for you, compare all our packages to find the best solution for your specific needs.
      </Typography>
    </>
  );

  const mobileText = (
    <>
      <Typography variant="body1" paragraph>
        Selecting the right water filtration package will ensure the best quality water for your home. Our packages are designed to cater to different needs and budgets.
      </Typography>
      <Typography variant="body1">
        Consider factors such as your water source, specific contaminants you want to remove, and your household's water consumption when making your choice.
      </Typography>
    </>
  );

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: { xs: 'column', md: 'row' }, 
      gap: { xs: 3, md: 4 },
      maxWidth: '1400px',
      mx: 'auto'
    }}>
      <Box sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        order: { xs: 1, md: 2 }
      }}>
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: 'primary.main'
          }}
        >
          Choose Your Ideal Package
        </Typography>
        {isMobile ? mobileText : desktopText}
      </Box>

      <Box sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        order: { xs: 2, md: 1 },
        width: '100%'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%',
          mb: 2,
          px: { xs: 1, sm: 2 }
        }}>
          <IconButton 
            onClick={prevPackage} 
            sx={{ 
              mr: { xs: 1, sm: 2 },
              '& svg': { fontSize: { xs: '1.2rem', sm: '1.5rem' } }
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          
          <Card sx={{ 
            flex: '0 1 500px',
            display: 'flex', 
            flexDirection: 'column', 
            boxShadow: 3,
            maxWidth: '500px',
            width: '100%'
          }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography 
                gutterBottom 
                variant={isMobile ? "h5" : "h4"} 
                component="h2" 
                align="center"
              >
                {packages[currentPackage].title}
              </Typography>
              <Typography 
                variant={isMobile ? "h4" : "h3"} 
                component="p" 
                align="center" 
                sx={{ my: 2, color: 'primary.main' }}
              >
                {packages[currentPackage].price}
              </Typography>
              <List>
                {packages[currentPackage].features.map((feature, idx) => (
                  <ListItem key={idx} disablePadding sx={{ py: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: { xs: 36, sm: 42 } }}>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={feature} 
                      sx={{ 
                        '& .MuiTypography-root': { 
                          fontSize: { xs: '0.9rem', sm: '1rem' } 
                        } 
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
              <Button 
                variant="contained" 
                color="primary" 
                size="large"
                onClick={handleChoosePlan}
                sx={{ width: { xs: '90%', sm: 'auto' } }}
              >
                Choose Plan
              </Button>
            </CardActions>
          </Card>

          <IconButton 
            onClick={nextPackage} 
            sx={{ 
              ml: { xs: 1, sm: 2 },
              '& svg': { fontSize: { xs: '1.2rem', sm: '1.5rem' } }
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          {packages.map((_, index) => (
            <FiberManualRecordIcon 
              key={index} 
              sx={{ 
                fontSize: { xs: 10, sm: 12 }, 
                mx: 0.5, 
                color: index === currentPackage ? 'primary.main' : 'grey.400'
              }} 
            />
          ))}
        </Box>

        <Button 
          variant="outlined" 
          color="primary" 
          size="large" 
          sx={{ 
            mt: 4,
            width: { xs: '100%', sm: 'auto' },
            maxWidth: '500px'
          }}
          onClick={handleComparePackages}
        >
          Compare Packages
        </Button>
      </Box>
    </Box>
  );
}

export default FilterPackages;