import React from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FAQComponent from '../components/FAQComponent';
import FAQchat from '../components/FAQchat';

function FAQPage() {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          background: 'linear-gradient(135deg, #2196F3, #21CBF3)',
          pt: 10,
          pb: 6,
          color: 'white'
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
            Frequently Asked Questions
          </Typography>
          <Typography variant="h5" align="center" paragraph>
            Find answers to common questions about our water filtration solutions
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <FAQComponent />
      </Container>
      <FAQchat />
      <Footer />
    </>
  );
}

export default FAQPage;