import React from 'react';
import { Box, Typography, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { Lightbulb, OpacityOutlined, Plumbing, FilterAlt } from '@mui/icons-material';

const services = [
  { name: 'Consulting', icon: Lightbulb, description: 'Expert advice' },
  { name: 'Water Testing', icon: OpacityOutlined, description: 'Quality analysis' },
  { name: 'Countertop Drilling', icon: Plumbing, description: 'Spout installation' },
  { name: 'Filtration Setup', icon: FilterAlt, description: 'System installation' },
];

function Specialization() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ my: 8, textAlign: 'center' }}>
      <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
        Our Specialization
      </Typography>
      <Typography variant="h5" component="h3" gutterBottom sx={{ mb: 6, color: theme.palette.text.secondary, fontWeight: 400 }}>
        We specialize in under-countertop filtration, providing comprehensive solutions for your water needs
      </Typography>
      <Grid container spacing={isDesktop ? 2 : 3} justifyContent="center">
        {services.map((service, index) => (
          <Grid 
            item 
            xs={6} 
            md={3} 
            key={index}
          >
            <Paper 
              elevation={3} 
              sx={{
                p: isDesktop ? 2 : 1.5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 6,
                },
                minHeight: isDesktop ? 'auto' : '140px'
              }}
            >
              <Box sx={{ 
                backgroundColor: theme.palette.primary.main, 
                borderRadius: '50%', 
                p: isDesktop ? 1.5 : 1,
                mb: isDesktop ? 1 : 0.5,
                color: 'white',
              }}>
                <service.icon fontSize={isDesktop ? "medium" : "small"} />
              </Box>
              <Typography 
                variant={isDesktop ? "subtitle1" : "subtitle2"} 
                component="h4" 
                gutterBottom 
                sx={{ 
                  fontWeight: 500,
                  mb: isDesktop ? 1 : 0.5
                }}
              >
                {service.name}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{
                  fontSize: isDesktop ? undefined : '0.75rem',
                }}
              >
                {service.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Specialization;
