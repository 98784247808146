import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  useTheme, 
  Paper,
  TextField,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

function ContactPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = Object.fromEntries(formData.entries());
    
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'form-name': 'contact',
          ...formObject
        }).toString()
      });
      
      // Navigate to thank you page with form data
      navigate('/thank-you', { 
        state: { 
          formData: formObject,
          formType: 'contact'
        } 
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ background: '#f8f9fa', pt: 10, pb: 6 }}>
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              sx={{ fontWeight: 500, color: theme.palette.primary.main, mb: 2 }}
            >
              Contact Us
            </Typography>
            <Typography 
              variant="subtitle1" 
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '600px',
                margin: '0 auto',
                lineHeight: 1.6,
              }}
            >
              Have questions? We're here to help with all your water filtration needs.
            </Typography>
          </Box>

          <Paper 
            elevation={2} 
            sx={{ 
              p: 4,
              borderRadius: 2,
              maxWidth: '600px',
              margin: '0 auto',
              mb: 8,
            }}
          >
            <form 
              onSubmit={handleSubmit}
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don't fill this out: <input name="bot-field" />
                </label>
              </p>
              
              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  required
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  required
                  type="email"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="phone"
                  label="Phone (optional)"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="message"
                  label="Message"
                  required
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={{ mb: 3 }}
                />
                <Button 
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    }
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default ContactPage;