import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Card, CardContent, CardMedia, Button, Grid } from '@mui/material';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CompareIcon from '@mui/icons-material/Compare';
import NatureIcon from '@mui/icons-material/Nature';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Information1 = () => {
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileTexts = {
    quality: "Our advanced filtration systems remove 99% of contaminants, ensuring pure water for your home. Enjoy better taste and clarity.",
    health: "Protect your family by reducing exposure to contaminants. Our systems support better hydration and healthier living."
  };

  return (
    <Box sx={{ py: 8 }}>
      <Typography variant="h3" component="h2" gutterBottom align="center" 
        sx={{ 
          color: '#333333', 
          fontWeight: 500,
          fontSize: isMobile ? '2rem' : '3rem' 
        }}>
        Why Filter Your Water?
      </Typography>
      
      {!isMobile && (
        <Typography variant="h6" component="h3" gutterBottom align="center" 
          sx={{ mb: 4, color: '#555555', fontWeight: 400 }}>
          Discover the benefits of clean, filtered water for you and your family
        </Typography>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="water filtration benefits" centered>
          <Tab icon={<HealthAndSafetyIcon />} label="Health" />
          <Tab icon={<WaterDropIcon />} label="Quality" />
          {!isMobile && <Tab icon={<NatureIcon />} label="Environment" />}
          {!isMobile && <Tab icon={<CompareIcon />} label="Filter vs Tap" />}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', maxWidth: 400, height: 'auto' }}
                image="/waterfamily.png"
                alt="Health benefits of filtered water"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" gutterBottom color="primary" 
              sx={{ 
                fontWeight: 500,
                fontSize: isMobile ? '1.75rem' : '2.125rem'
              }}>
              Health & Safety Benefits
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              {isMobile ? mobileTexts.health : "Protect your family's health by reducing exposure to harmful contaminants like lead, bacteria, and chlorine. Our filtration systems support better hydration and even protect sensitive skin from harsh chemicals found in tap water."}
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Reduced exposure to contaminants
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Improved hydration for overall wellness
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                • Healthier skin and hair from gentler water
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', maxWidth: 400, height: 'auto' }}
                image="/faucetwater.png"
                alt="Water quality comparison"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" gutterBottom color="primary" 
              sx={{ 
                fontWeight: 500,
                fontSize: isMobile ? '1.75rem' : '2.125rem'
              }}>
              Superior Water Quality
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              {isMobile ? mobileTexts.quality : "Our advanced filtration systems remove up to 99% of contaminants, ensuring crystal-clear water for drinking and cooking. Say goodbye to chlorine taste and odor, and hello to refreshing, pure water from every tap in your home."}
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Enjoy crisp, clean-tasting water
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Eliminate unpleasant chlorine smells
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                • Crystal-clear water for all your needs
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', maxWidth: 400, height: 'auto' }}
                image="/environmental.png"
                alt="Environmental impact"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 500 }}>Environmental Impact</Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
              Choosing filtered water offers health benefits while also reducing plastic waste from bottled water use. It promotes a more sustainable approach to water consumption, helping to minimize waste and encourage responsible habits.
            </Typography>
            <Typography variant="h6" gutterBottom color="secondary" sx={{ fontWeight: 500 }}>
              Environmental benefits:
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Significant reduction in plastic waste
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                • Promotion of sustainable water usage
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                • Encouragement of eco-friendly habits
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Card elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom align="center" color="primary" sx={{ mb: 3 }}>
              Filtered Water vs. Tap Water
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom color="secondary">Filtered Water</Typography>
                <Box sx={{ pl: 2 }}>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • Removes harmful contaminants
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • Improves taste and odor
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • Consistent quality
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                    • Healthier for cooking and drinking
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom color="secondary">Tap Water</Typography>
                <Box sx={{ pl: 2 }}>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • May contain contaminants
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • Often has chlorine taste and odor
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 1 }}>
                    • Quality varies by location
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                    • Can contain harmful chemicals
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </TabPanel>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom 
          sx={{ 
            color: '#333333', 
            fontWeight: 500,
            fontSize: isMobile ? '1.75rem' : '2.125rem'
          }}>
          Ready to Experience Pure, Clean Water?
        </Typography>
        <Button 
          component={RouterLink}
          to="/packages"
          variant="contained" 
          color="primary" 
          size="large"
          endIcon={<ArrowForwardIcon />}
          sx={{
            mt: 2,
            py: 1.5,
            px: 4,
            fontSize: '1.2rem',
            fontWeight: 500,
            borderRadius: '30px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          {isMobile ? 'Explore Solutions' : 'Explore Our Filtration Solutions'}
        </Button>
      </Box>
    </Box>
  );
};

export default Information1;
