import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, TextField, IconButton, Paper, Typography, CircularProgress, Avatar, Button, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import WaterDropIcon from '@mui/icons-material/WaterDrop';

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [showInitialQuestions, setShowInitialQuestions] = useState(true);
  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const initialQuestions = [
    "What types of water filters are there?",
    "How do I choose the right filter?",
    "What's in my water that needs filtering?",
    "Under sink or whole house filter?"
  ];

  const handleQuestionClick = (question) => {
    setInput(question);
    setShowInitialQuestions(false);
    setIsExpanded(true);
    sendMessage(null, question);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      const height = chatContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    // Initialize the conversation with the new greeting from the bot
    // Commenting out the initial message
    /*
    const initialMessage = {
      text: "Let's choose a filter that's right for your household. Choose a starting point below.",
      sender: 'bot'
    };
    setMessages([initialMessage]);
    */
    // If you want to start with an empty message array, you can do:
    setMessages([]);
  }, []);

  const sendMessage = async (e, questionText = null) => {
    if (e) e.preventDefault();
    const messageText = questionText || input;
    if (messageText.trim() === '') return;

    // Hide initial questions when the first message is sent
    setShowInitialQuestions(false);

    const newMessage = { text: messageText, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('http://localhost:3001/api/chat', { 
        message: messageText,
        context: messages // Send the entire conversation history
      });
      const botMessage = { text: response.data.message, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { text: 'Sorry, I encountered an error. Please try again.', sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      inputRef.current?.focus();
    }
  };

  // Focus the input field when the component mounts
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: isExpanded ? '400px' : 'auto',
      width: '100%',
      maxWidth: '600px',
      margin: 'auto',
      bgcolor: 'background.paper',
      borderRadius: 2,
      overflow: 'hidden',
      boxShadow: 3,
      transition: 'height 0.3s ease-in-out',
    }}>
      <Paper elevation={0} sx={{ 
        flex: 1, 
        overflowY: 'auto', 
        p: 2, 
        mb: isExpanded ? 2 : 0,
        display: isExpanded ? 'block' : 'none'
      }} ref={chatContainerRef}>
        {messages.map((message, index) => (
          <Box key={index} sx={{ 
            display: 'flex', 
            justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
            mb: 2 
          }}>
            {message.sender === 'bot' && (
              <Avatar sx={{ bgcolor: 'primary.main', mr: 1 }}>
                <WaterDropIcon />
              </Avatar>
            )}
            <Paper
              elevation={1}
              sx={{
                p: 2,
                maxWidth: '70%',
                borderRadius: 2,
                bgcolor: message.sender === 'user' ? 'primary.light' : 'white',
              }}
            >
              <Typography variant="body1" color={message.sender === 'user' ? 'primary.contrastText' : 'text.primary'}>
                {message.text}
              </Typography>
            </Paper>
            {message.sender === 'user' && (
              <Avatar sx={{ bgcolor: 'secondary.main', ml: 1 }}>
                {/* You can replace this with a user icon if preferred */}
                U
              </Avatar>
            )}
          </Box>
        ))}
        {showInitialQuestions && messages.length === 0 && (
          <Box sx={{ mt: 2 }}>
            {/* <Typography variant="h6" align="center" gutterBottom>
              Let's choose a filter that's right for your household. Choose a starting point below:
            </Typography> */}
            <Grid container spacing={2}>
              {initialQuestions.map((question, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleQuestionClick(question)}
                    fullWidth
                    sx={{ height: '100%', textAlign: 'center', whiteSpace: 'normal' }}
                  >
                    {question}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <div ref={chatContainerRef} style={{ height: '1px', marginBottom: '-1px' }} />
      </Paper>

      {!isExpanded && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {initialQuestions.map((question, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleQuestionClick(question)}
                  fullWidth
                  sx={{ height: '100%', textAlign: 'center', whiteSpace: 'normal' }}
                >
                  {question}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {isExpanded && (
        <Paper 
          component="form" 
          onSubmit={sendMessage}
          sx={{ 
            p: '2px 4px', 
            display: 'flex', 
            alignItems: 'center', 
            borderRadius: '0 0 16px 16px',
            boxShadow: 'none',
            borderTop: '1px solid #e0e0e0',
          }}
        >
          <TextField
            fullWidth
            variant="standard"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="or just let us know what's on your mind..."
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ ml: 1, flex: 1 }}
            disabled={isLoading}
            inputRef={inputRef}
          />
          <IconButton type="submit" color="primary" sx={{ p: '10px' }} aria-label="send" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Paper>
      )}
    </Box>
  );
}

export default ChatBot;