import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardActions, Button, List, ListItem, ListItemIcon, ListItemText, Chip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PackagesChat from '../components/PackagesChat';
import PackageComparison from '../components/PackageComparison';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme, useMediaQuery } from '@mui/material';

const packages = [
  {
    id: 1,
    title: "Basic Filtration",
    price: "$115",
    features: [
      "Baseline filtration",
      "Removes chlorine and sediment",
      "Stainless Steel filter housings",
      "In-line with existing faucet",
      "Filter replacement alert system ",
      "Great addition to bathroom sinks"
    ],
    color: 'info'
  },
  {
    id: 2,
    title: "Essential Filtration",
    price: "$235",
    features: [
      "3 stage filtration",
      "99.99% of Contąminants Larger Than 0.01μm",
      "No impact to existing water pressure",
      "Removes chlorine and sediment",
      "Reduces 95% of PFAS",
      "Retains mineral content for taste and electrolite balance",
    ],
    color: 'success'
  },
  {
    id: 3,
    title: "Advanced Filtration",
    price: "$685",
    features: [
      "3-Stage, carbon filtration",
      "Targets chlorine, fluoride, lead, PFAS & more, without removing beneficial minerals.",
      "Removes up to 99.99% of 232+ contaminants",
      "Avaiable as in-line or dedicated faucet",
      "Low maintanece cost. Filters life > 1 year"
    ],
    color: 'secondary'
  },
  {
    id: 4,
    title: "Elite Filtration",
    price: "$885",
    features: [
      "State-of-the-Art Tankless Reverse Osmosis",
      "9-layer, 0.0001 filtration precision.", 
      "Removes 1000+ harmful contaminants (99.99%)",
      "Pressurized for instant flow RO",
      "Dedicated faucet (Choice of style and finish)",
      "Remineralization system included",
      "Electirical outlet required"
    ],
    color: 'primary'
  }
];

function PackagesPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedCards, setExpandedCards] = React.useState({});

  const handleChoosePlan = (packageId) => {
    navigate(`/get-a-quote?package=${packageId}`);
  };

  const toggleExpand = (index, event) => {
    event.stopPropagation();
    setExpandedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderFeatures = (features, index) => {
    if (!isMobile) {
      return features;
    }

    const initialFeatures = features.slice(0, 3);
    const remainingFeatures = features.slice(3);
    const isExpanded = expandedCards[index];

    return isExpanded ? features : initialFeatures;
  };

  return (
    <>
      <Header />
      <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
        <Container maxWidth="lg">
          {/* Title section remains unchanged */}
          <Box sx={{ 
            mb: 6, 
            textAlign: 'center',
          }}>
            <Typography 
              variant="h2" 
              component="h1" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 2,
              }}
            >
              Our Filtration Packages
            </Typography>
            <Typography variant="h5" color="text.secondary">
              Invest in your health and well-being with our advanced water filtration packages. Choose the perfect solution for your home and enjoy clean, great-tasting water every day.
            </Typography>
          </Box>

          {/* Updated package cards with different button colors */}
          <Grid container spacing={4} justifyContent="center" sx={{ mb: 4 }}>
            {packages.map((pkg, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <Card sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  boxShadow: 3, 
                  position: 'relative',
                  overflow: 'visible'
                }}>
                  {pkg.title === "Advanced Filtration" && (
                    <Chip
                      icon={<StarIcon />}
                      label="Most Popular"
                      color="secondary"
                      sx={{
                        position: 'absolute',
                        top: -16,
                        left: 0,
                        right: 0,
                        height: 32,
                        borderRadius: '16px 16px 0 0',
                        '& .MuiChip-label': {
                          fontSize: '0.9rem',
                          fontWeight: 'bold',
                        },
                        '& .MuiChip-icon': {
                          fontSize: '1.2rem',
                        },
                      }}
                    />
                  )}
                  <CardContent sx={{ flexGrow: 1, pt: pkg.title === "Advanced Filtration" ? 4 : 2 }}>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {pkg.title}
                    </Typography>
                    <Typography variant="h4" component="p" align="center" sx={{ my: 2, color: 'primary.main' }}>
                      {pkg.price}
                    </Typography>
                    <List>
                      {renderFeatures(pkg.features, index).map((feature, idx) => (
                        <ListItem key={idx} disablePadding>
                          <ListItemIcon>
                            <CheckCircleOutlineIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={feature} />
                        </ListItem>
                      ))}
                    </List>
                    {isMobile && pkg.features.length > 3 && (
                      <Button
                        onClick={(e) => toggleExpand(index, e)}
                        startIcon={expandedCards[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        sx={{ mt: 1, width: '100%' }}
                      >
                        {expandedCards[index] ? 'Show Less' : `${pkg.features.length - 3} More Features`}
                      </Button>
                    )}
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                    <Button 
                      variant="contained" 
                      color={pkg.color} 
                      size="large"
                      onClick={() => handleChoosePlan(pkg.id)}
                      sx={{
                        '&:hover': {
                          backgroundColor: `${pkg.color}.dark`,
                        },
                      }}
                    >
                      Choose Plan
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* New section for whole house filtration message */}
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                fontStyle: 'italic',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '12px',
                borderTop: '1px solid #e0e0e0',
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              Whole house filtration systems require an on-site consultation. If you would like a custom quote, send us a message with some details about what you are looking for.
            </Typography>
          </Box>
        
          <Box sx={{ mb: 8 }}>
            <PackageComparison />
          </Box>

          <Box sx={{ mb: 4 }}>
            <PackagesChat />
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default PackagesPage;
