import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: "What types of water filtration systems do you offer?",
    answer: "We offer a wide range of water filtration systems including whole-house filtration, under-sink filters, reverse osmosis systems, and point-of-use solutions. Our experts can help you choose the best system for your specific needs."
  },
  {
    question: "How often should I replace my water filter?",
    answer: "The frequency of filter replacement depends on the type of system you have and your water usage. Generally, filters should be replaced every 3-6 months for optimal performance. We provide specific guidelines for each system we install."
  },
  {
    question: "Do you offer water testing services?",
    answer: "Yes, we offer comprehensive water testing services to identify contaminants and help you choose the most effective filtration solution for your home or business."
  },
  {
    question: "Can you install a water filtration system in my home?",
    answer: "Absolutely! Our team of certified professionals can install any of our water filtration systems in your home. We ensure proper installation and provide guidance on maintenance and use."
  },
  // Add more FAQ items as needed
];

function FAQComponent() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ mb: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default FAQComponent;