import React from 'react';
import { Container, Typography, Box, useTheme, Paper, Divider } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import QuoteForm from '../components/QuoteForm';
// Remove the import for RequestQuoteIcon as it's no longer needed
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

function GetAQuote() {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          backgroundColor: theme.palette.background.default,
          pt: 6,
          pb: 15,
        }}
      >
        <Container maxWidth="lg">
          {/* Title and Info Section */}
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Time to get filtered. 
            </Typography>
            <Typography variant="h5" sx={{ mb: 3, color: theme.palette.text.secondary }}>
              Receive a personalized quote for your water filtration needs
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="body1" sx={{ maxWidth: '800px', margin: '0 auto' }}>
              Fill out our simple form to get a tailored quote for your water filtration system. Our experts will review your requirements and provide you with a competitive price for the perfect solution.
            </Typography>
          </Box>

          {/* Removed repetitive content */}

          <Paper 
            elevation={6} 
            sx={{ 
              p: 4, 
              borderRadius: 3,
              background: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
            }}
          >
            <Typography variant="h5" component="h3" gutterBottom sx={{ color: 'white', mb: 3 }}>
              Fill out the form below to get your personalized quote
            </Typography>
            <Box sx={{ mt: 2 }}>
              <QuoteForm />
            </Box>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default GetAQuote;