import React from 'react';
import { Grid, Typography, Button, Paper, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: '10px 20px',
  fontWeight: 500,
  textTransform: 'none',
  marginTop: 'auto',
  width: 'fit-content',
  alignSelf: 'center',
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '60%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  borderRadius: '8px',
  marginTop: '16px',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    marginTop: '8px',
  },
}));

const WaterQuality = () => {
  const handleOrderTestKit = () => {
    window.open('https://mytapscore.com/collections/city-water-test-kits', '_blank');
  };

  const handleSearchZipcode = () => {
    window.open('https://www.ewg.org/tapwater/', '_blank');
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: { xs: 3, md: 4 },
      padding: { xs: 2, md: 0 }
    }}>
      <Box sx={{ width: '100%' }}>
        <StyledPaper elevation={3}>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              fontWeight: 500, 
              textAlign: 'center', 
              color: '#0277bd',
              fontSize: { xs: '1.6rem', md: '2.125rem' },
              mb: { xs: 1, md: 2 }
            }}
          >
            City Water Test Kits
          </Typography>
          <StyledImage src="/tapscore.png" alt="City Water Test Kit" />
          <Typography variant="body1" sx={{ 
            mt: { xs: 2, md: 3 }, 
            mb: { xs: 1, md: 2 }, 
            textAlign: 'center', 
            fontSize: { xs: '1rem', md: '1.1rem' }
          }}>
            Recommended for basic water quality testing in any home, building,
            or facility served by a water utility.
          </Typography>
          <List sx={{ py: { xs: 0, md: 1 } }}>
            {['Certified lab testing', 'Fast turnaround at lab (5 days)', 
              'Free shipping, both ways', 'Expert support'].map((feature, index) => (
              <ListItem key={index} dense sx={{ py: { xs: 0.5, md: 1 } }}>
                <ListItemIcon>
                  <CheckCircleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary={feature} 
                  primaryTypographyProps={{ 
                    fontSize: { xs: '0.95rem', md: '1.1rem' }
                  }}
                />
              </ListItem>
            ))}
          </List>
          <StyledButton 
            variant="contained" 
            color="primary" 
            size="large"
            onClick={handleOrderTestKit}
          >
            Order a Test Kit
          </StyledButton>
        </StyledPaper>
      </Box>

      <Box sx={{ width: '100%' }}>
        <StyledPaper elevation={3}>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              fontWeight: 500, 
              textAlign: 'center', 
              color: '#0277bd',
              fontSize: { xs: '1.6rem', md: '2.125rem' },
              mb: { xs: 1, md: 2 }
            }}
          >
            EWG Tap Water Database
          </Typography>
          <StyledImage src="/zipcode.png" alt="EWG Tap Water Database" />
          <Typography variant="h6" sx={{ 
            mt: { xs: 2, md: 3 }, 
            mb: { xs: 1, md: 2 }, 
            fontWeight: 500, 
            textAlign: 'center',
            fontSize: { xs: '1.1rem', md: '1.25rem' }
          }}>
            Know your environment. Protect your health.
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: { xs: 2, md: 3 }, 
            textAlign: 'center', 
            fontSize: { xs: '0.95rem', md: '1.1rem' }
          }}>
            EWG's drinking water quality report shows results of tests conducted
            by the water utility and provided to the Environmental Working Group.
            Check the latest compliance status with federal health-based drinking water standards.
          </Typography>
          <StyledButton 
            variant="contained" 
            color="primary" 
            size="large"
            onClick={handleSearchZipcode}
            sx={{ mt: 'auto' }}
          >
            Search your Zipcode on EWG
          </StyledButton>
        </StyledPaper>
      </Box>
    </Box>
  );
};

export default WaterQuality;
