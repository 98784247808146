import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Ready() {
  return (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h3" component="h2" gutterBottom>
        Ready to{' '}
        <Link
          component={RouterLink}
          to="/get-a-quote"
          sx={{
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          filter your water?
        </Link>
      </Typography>
    </Box>
  );
}

export default Ready;