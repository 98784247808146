import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import HomePage from '../pages/HomePage';
import TestYourWater from '../pages/TestYourWater';
import ProcessPage from '../pages/ProcessPage';
import ChatbotPage from '../components/ChatbotPage';
import Testimonials from '../components/TestimonialsPage';
import ChooseYourSystem from '../pages/ChooseYourSystem';
import PackagesPage from '../pages/PackagesPage';
import GetAQuote from '../pages/GetAQuote';
import FAQPage from '../pages/FAQPage';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/ContactPage';
import ThankYou from '../pages/ThankYou';

const routes = [
  {
    path: '/',
    name: 'Home',
    element: <HomePage />,
  },
  {
    path: '/test-your-water',
    name: 'TestYourWater',
    element: <TestYourWater />,
  },
  {
    path: '/process',
    name: 'Process',
    element: <ProcessPage />,
  },
  {
    path: '/chatbot',
    name: 'Chatbot',
    element: <ChatbotPage />,
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    element: <Testimonials />,
  },
  {
    path: '/chooseyoursystem',
    name: 'ChooseYourSystem',
    element: <ChooseYourSystem />,
  },
  {
    path: '/packages',
    name: 'Packages',
    element: <PackagesPage />,
  },
  {
    path: '/get-a-quote',
    name: 'GetAQuote',
    element: <GetAQuote />,
  },
  {
    path: '/faq',
    name: 'FAQ',
    element: <FAQPage />,
  },
  {
    path: '/about',
    name: 'About',
    element: <AboutPage />,
  },
  {
    path: '/contact',
    name: 'Contact',
    element: <ContactPage />,
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    element: <ThankYou />,
  },
];

function AppRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default AppRouter;