import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  useTheme, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton, 
  Modal, 
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import SecurityIcon from '@mui/icons-material/Security';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

function PackageComparison() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(false);

  const filtrationSystems = [
    { 
      type: 'Basic Filtration',
      icon: <PlumbingIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      priceRange: '$115',
      description: "Baseline filtration for improved water quality at an affordable price.",
      filterCapabilities: {
        'Removes chlorine': true,
        'Removes sediment': true,
        'Improves taste': true,
        'Removes heavy metals': false,
        'Reduces PFAS': false,
        'Removes fluoride': false,
        'Removes bacteria': false,
      },
      hardware: {
        'Filter Housing': 'Stainless Steel',
        'Installation': 'In-line with existing faucet',
        'Maintenance': 'Filter replacement alert system',
        'Recommended Use': 'Great addition to bathroom sinks',
      }
    },
    { 
      type: 'Essential Filtration',
      icon: <FilterAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      priceRange: '$235',
      description: "3-stage filtration system offering comprehensive water treatment for high-quality drinking water.",
      filterCapabilities: {
        'Removes chlorine': true,
        'Removes sediment': true,
        'Improves taste': true,
        'Removes heavy metals': true,
        'Reduces PFAS': true,
        'Removes fluoride': false,
        'Removes bacteria': true,
      },
      hardware: {
        'Filter Housing': '3-stage system',
        'Installation': 'No impact to existing water pressure',
        'Maintenance': 'Regular filter replacement',
        'Recommended Use': 'Kitchen and drinking water',
      }
    },
    { 
      type: 'Advanced Filtration',
      icon: <WaterDropIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      priceRange: '$685',
      description: "3-Stage, carbon filtration system targeting a wide range of contaminants while retaining beneficial minerals.",
      filterCapabilities: {
        'Removes chlorine': true,
        'Removes sediment': true,
        'Improves taste': true,
        'Removes heavy metals': true,
        'Reduces PFAS': true,
        'Removes fluoride': true,
        'Removes bacteria': true,
      },
      hardware: {
        'Filter Housing': '3-Stage, carbon filtration',
        'Installation': 'Available as in-line or dedicated faucet',
        'Maintenance': 'Low maintenance cost, filters life > 1 year',
        'Recommended Use': 'Whole kitchen or whole house',
      }
    },
    { 
      type: 'Elite Filtration',
      icon: <SecurityIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      priceRange: '$885',
      description: "State-of-the-Art Tankless Reverse Osmosis system offering the most comprehensive water treatment for maximum safety and quality.",
      filterCapabilities: {
        'Removes chlorine': true,
        'Removes sediment': true,
        'Improves taste': true,
        'Removes heavy metals': true,
        'Reduces PFAS': true,
        'Removes fluoride': true,
        'Removes bacteria': true,
      },
      hardware: {
        'Filter Housing': '9-layer, 0.0001 filtration precision',
        'Installation': 'Dedicated faucet (Choice of style and finish)',
        'Maintenance': 'Regular filter replacement',
        'Recommended Use': 'Whole house system',
      }
    },
  ];

  const handleOpenModal = (system) => {
    setSelectedSystem(system);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const renderMobileView = () => (
    <Box sx={{ mt: 4 }}>
      {filtrationSystems.map((system, index) => (
        <Accordion 
          key={index}
          expanded={expandedPanel === `panel${index}`}
          onChange={handleAccordionChange(`panel${index}`)}
          sx={{
            mb: 2,
            '&:before': {
              display: 'none',
            },
            boxShadow: theme.shadows[2],
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              },
            }}
          >
            {React.cloneElement(system.icon, { 
              sx: { fontSize: 24, color: theme.palette.primary.contrastText } 
            })}
            <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
              {system.type} - {system.priceRange}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box sx={{ p: 2, backgroundColor: theme.palette.grey[50] }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {system.description}
              </Typography>
            </Box>
            <Divider />
            <List dense>
              <ListItem sx={{ backgroundColor: theme.palette.grey[100] }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Filter Capabilities
                </Typography>
              </ListItem>
              {Object.entries(system.filterCapabilities).map(([feature, included], idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    {included ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={feature}
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              ))}
              <ListItem sx={{ backgroundColor: theme.palette.grey[100] }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Hardware Specifications
                </Typography>
              </ListItem>
              {Object.entries(system.hardware).map(([feature, value], idx) => (
                <ListItem key={idx}>
                  <ListItemText 
                    primary={feature}
                    secondary={value}
                    primaryTypographyProps={{
                      variant: 'body2',
                      fontWeight: 'medium',
                    }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  return (
    <Box sx={{ my: 8, px: 2 }}>
      <Typography 
        variant="h3" 
        component="h2" 
        gutterBottom 
        sx={{ 
          fontWeight: 500,
          color: theme.palette.primary.main,
          mb: 2,
          textAlign: 'center',
          fontSize: { xs: '2rem', md: '3rem' }
        }}
      >
        Package Comparison
      </Typography>
      <Typography 
        variant="subtitle1" 
        sx={{
          color: theme.palette.text.secondary,
          maxWidth: '600px',
          margin: '0 auto',
          lineHeight: 1.6,
          textAlign: 'center',
          mb: 4,
        }}
      >
        Packages designed to cater to different needs and budgets.
      </Typography>
      
      {isMobile ? renderMobileView() : (
        <Box sx={{ 
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 2,
          boxShadow: 3,
        }}>
          <Box sx={{ overflowX: 'auto' }}>
            <Table sx={{ 
              minWidth: { xs: 600, md: '100%' },
              borderCollapse: 'separate',
              borderSpacing: 0,
            }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                  <TableCell 
                    sx={{ 
                      color: theme.palette.primary.contrastText, 
                      fontWeight: 700, 
                      width: '120px',
                      minWidth: '120px',
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      backgroundColor: theme.palette.primary.light,
                      py: 1,
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '1px',
                        backgroundColor: theme.palette.divider,
                        zIndex: 3,
                      }
                    }}
                  >
                    Features
                  </TableCell>
                  {filtrationSystems.map((system, index) => (
                    <TableCell 
                      key={index} 
                      align="center" 
                      sx={{ 
                        borderLeft: `1px solid ${theme.palette.divider}`, 
                        color: theme.palette.primary.contrastText,
                        minWidth: { xs: '150px', md: '200px' },
                        py: 1
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        px: { xs: 1, md: 2 }
                      }}>
                        {React.cloneElement(system.icon, { 
                          sx: { 
                            fontSize: { xs: 32, md: 40 },
                            color: theme.palette.primary.contrastText 
                          } 
                        })}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5 }}>
                          <Typography 
                            variant="h6" 
                            component="h3" 
                            sx={{ 
                              fontWeight: 700, 
                              mr: 1,
                              fontSize: { xs: '0.9rem', md: '1.25rem' }
                            }}
                          >
                            {system.type}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => handleOpenModal(system)}
                            sx={{ color: theme.palette.primary.contrastText, p: 0 }}
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell 
                    colSpan={5} 
                    sx={{ 
                      backgroundColor: theme.palette.grey[100], 
                      color: theme.palette.text.secondary,
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      py: 1,
                      pl: 2,
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                    }}
                  >
                    Filter Capabilities
                  </TableCell>
                </TableRow>
                {Object.keys(filtrationSystems[0].filterCapabilities).map((feature, index) => (
                  <TableRow 
                    key={index} 
                    sx={{ 
                      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
                    }}
                  >
                    <TableCell 
                      component="th" 
                      scope="row" 
                      sx={{ 
                        fontWeight: 700, 
                        py: 1.5,
                        backgroundColor: theme.palette.grey[100],
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        color: theme.palette.text.primary,
                        position: 'sticky',
                        left: 0,
                        zIndex: 2,
                        width: '120px',
                        minWidth: '120px',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          bottom: 0,
                          width: '1px',
                          backgroundColor: theme.palette.divider,
                          zIndex: 3,
                        }
                      }}
                    >
                      {feature}
                    </TableCell>
                    {filtrationSystems.map((system, systemIndex) => (
                      <TableCell 
                        key={systemIndex} 
                        align="center" 
                        sx={{ 
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          py: 1.5,
                          minWidth: { xs: '150px', md: '200px' },
                          px: { xs: 1, md: 2 }
                        }}
                      >
                        {system.filterCapabilities[feature] ? (
                          <CheckIcon sx={{ color: theme.palette.success.main, fontSize: '1.3rem' }} />
                        ) : (
                          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>-</Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell 
                    colSpan={5} 
                    sx={{ 
                      backgroundColor: theme.palette.grey[100], 
                      color: theme.palette.text.secondary,
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      py: 1,
                      pl: 2,
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                    }}
                  >
                    Hardware
                  </TableCell>
                </TableRow>
                {Object.keys(filtrationSystems[0].hardware).map((feature, index) => (
                  <TableRow 
                    key={index} 
                    sx={{ 
                      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
                    }}
                  >
                    <TableCell 
                      component="th" 
                      scope="row" 
                      sx={{ 
                        fontWeight: 700, 
                        py: 1.5,
                        backgroundColor: theme.palette.grey[100],
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        color: theme.palette.text.primary,
                        position: 'sticky',
                        left: 0,
                        zIndex: 2,
                        width: '120px',
                        minWidth: '120px',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          bottom: 0,
                          width: '1px',
                          backgroundColor: theme.palette.divider,
                          zIndex: 3,
                        }
                      }}
                    >
                      {feature}
                    </TableCell>
                    {filtrationSystems.map((system, systemIndex) => (
                      <TableCell 
                        key={systemIndex} 
                        align="center" 
                        sx={{ 
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          py: 1.5,
                          minWidth: { xs: '150px', md: '200px' },
                          px: { xs: 1, md: 2 }
                        }}
                      >
                        {system.hardware[feature] ? (
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: theme.palette.text.primary,
                              fontWeight: 'bold'
                            }}
                          >
                            {system.hardware[feature]}
                          </Typography>
                        ) : (
                          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>-</Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 500 },
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          {selectedSystem && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                {React.cloneElement(selectedSystem.icon, { sx: { fontSize: 56, mr: 2 } })}
                <Typography id="modal-title" variant="h4" component="h2" sx={{ fontWeight: 700 }}>
                  {selectedSystem.type}
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
                {selectedSystem.priceRange}
              </Typography>
              <Typography id="modal-description" sx={{ mb: 3, fontSize: '1.1rem' }}>
                {selectedSystem.description}
              </Typography>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 700 }}>Key Features:</Typography>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                {Object.entries(selectedSystem.filterCapabilities).map(([feature, included]) => (
                  <Box key={feature} sx={{ display: 'flex', alignItems: 'center' }}>
                    {included && <CheckIcon sx={{ color: theme.palette.success.main, mr: 1 }} />}
                    <Typography variant="body1">{feature}</Typography>
                  </Box>
                ))}
              </Box>
              <Button 
                variant="contained" 
                onClick={handleCloseModal}
                fullWidth
                sx={{ mt: 4, py: 1.5, fontSize: '1.1rem' }}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default PackageComparison;
