import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BuildIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

function Installation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const installationSteps = [
    { 
      title: "From Quote to Install in Less Than 10 Days",
      mobileTitle: "Quick 10-Day Installation", 
      description: "We pride ourselves on our quick turnaround. From the moment you accept your quote, we'll have your new system installed within 10 days.",
      mobileDescription: "System installed within 10 days of quote acceptance",
      icon: ScheduleIcon
    },
    { 
      title: "Custom Installation of Your Selected Package",
      mobileTitle: "Expert Installation", 
      description: "Our expert technicians will carefully install your chosen filtration system, ensuring it's perfectly integrated with your home's water system.",
      mobileDescription: "Professional integration with your home's water system",
      icon: BuildIcon
    },
    { 
      title: "System Testing",
      mobileTitle: "Quality Testing", 
      description: "Once installed, we rigorously test your new system to ensure it's functioning at peak performance and delivering the water quality you expect.",
      mobileDescription: "Thorough testing for peak performance",
      icon: SpeedIcon
    },
    { 
      title: "Satisfaction Guaranteed",
      mobileTitle: "100% Guaranteed", 
      description: "We stand behind our work. If you're not completely satisfied with your installation, we'll make it right - that's our promise to you.",
      mobileDescription: "Your satisfaction is our priority",
      icon: ThumbUpIcon
    },
  ];

  return (
    <Box sx={{ my: 6 }}>
      <Typography 
        variant="h3" 
        component="h2" 
        gutterBottom 
        sx={{ 
          fontWeight: 500,
          color: theme.palette.primary.main,
          mb: 2,
          textAlign: 'center',
          fontSize: isMobile ? '2rem' : '3rem'
        }}
      >
        Our Installation Process
      </Typography>
      <Typography 
        variant="subtitle1" 
        sx={{
          color: theme.palette.text.secondary,
          maxWidth: '600px',
          margin: '0 auto',
          lineHeight: 1.6,
          textAlign: 'center',
          mb: 4,
          px: 2
        }}
      >
        Professional installation for a seamless integration into your home
      </Typography>
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
        gap: isMobile ? 2 : 3,
        px: isMobile ? 2 : 0,
        maxWidth: isMobile ? '600px' : '1200px',
        mx: 'auto'
      }}>
        {installationSteps.map((step, index) => (
          <Box 
            key={index} 
            sx={{ 
              display: 'flex',
              width: '100%'
            }}
          >
            <Box 
              sx={{ 
                p: isMobile ? '12px 16px' : 3,
                width: '100%',
                display: 'flex', 
                flexDirection: isMobile ? 'row' : 'column', 
                alignItems: isMobile ? 'flex-start' : 'center',
                textAlign: isMobile ? 'left' : 'center',
                bgcolor: '#ffffff',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'grey.200',
                boxShadow: isMobile ? 1 : 3,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: isMobile ? 2 : 4,
                  borderColor: 'grey.300',
                }
              }}
            >
              <step.icon sx={{ 
                fontSize: isMobile ? 28 : 40,
                color: theme.palette.primary.main, 
                mb: isMobile ? 0 : 2,
                mr: isMobile ? 2 : 0,
                flexShrink: 0,
                mt: isMobile ? '4px' : 0
              }} />
              <Box sx={{ 
                flex: 1,
                maxWidth: isMobile ? '85%' : '100%'
              }}>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  sx={{ 
                    fontWeight: 'bold', 
                    fontSize: isMobile ? '1.125rem' : '1.35rem',
                    mb: isMobile ? 0.5 : 1
                  }}
                >
                  {isMobile ? step.mobileTitle : step.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    fontSize: isMobile ? '1rem' : '1.1rem',
                    lineHeight: 1.5
                  }}
                >
                  {isMobile ? step.mobileDescription : step.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Installation;