import React from 'react';
import { Container, Typography, Box, useTheme, Grid, Paper } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedIcon from '@mui/icons-material/Verified';

function AboutPage() {
  const theme = useTheme();

  const values = [
    {
      icon: <WaterDropIcon sx={{ fontSize: 40 }} />,
      title: "Pure Water Commitment",
      description: "We're dedicated to providing households with clean, safe drinking water through advanced filtration solutions."
    },
    {
      icon: <EngineeringIcon sx={{ fontSize: 40 }} />,
      title: "Expert Installation",
      description: "Our certified technicians ensure proper installation and maintenance of your water filtration systems."
    },
    {
      icon: <VerifiedIcon sx={{ fontSize: 40 }} />,
      title: "Quality Assurance",
      description: "We use only certified, high-quality filtration components that meet or exceed industry standards."
    }
  ];

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          background: '#f8f9fa',
          pt: 10,
          pb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: 8,
            }}
          >
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.primary.main,
                mb: 2,
              }}
            >
              About Us
            </Typography>
            <Typography 
              variant="subtitle1" 
              sx={{
                color: theme.palette.text.secondary,
                maxWidth: '600px',
                margin: '0 auto',
                lineHeight: 1.6,
              }}
            >
              Delivering clean water solutions to homes since 2023
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ my: 8 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
              Our Story
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: theme.palette.text.secondary, lineHeight: 1.8 }}>
              We started with a simple mission: to make clean, safe drinking water accessible to every household. Our journey began when we realized the complexity and confusion surrounding home water filtration systems.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: theme.palette.text.secondary, lineHeight: 1.8 }}>
              Today, we've helped thousands of families achieve better water quality through our innovative filtration solutions and straightforward approach to water treatment.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3}
              sx={{ 
                height: '100%',
                minHeight: 300,
                backgroundImage: 'url(/waterfamily.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 2
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 10 }}>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              textAlign: 'center',
              fontWeight: 500,
              color: theme.palette.primary.main,
              mb: 6
            }}
          >
            Our Values
          </Typography>
          <Grid container spacing={4}>
            {values.map((value, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 4,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 2,
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)'
                    }
                  }}
                >
                  <Box sx={{ color: theme.palette.primary.main, mb: 2 }}>
                    {value.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                    {value.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                    {value.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default AboutPage; 