import React, { useState } from 'react';
import { Box, Button, Typography, Collapse } from '@mui/material';
import ChatBot from './FAQChatBot';

const CallToAction1 = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <Box sx={{ py: 8, textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Have a question of your own?
      </Typography>
      <Typography variant="body1" paragraph>
        Let our water expert chatbot answer all your questions!
      </Typography>
      <Box>
        <Button
          onClick={toggleChat}
          variant="contained"
          color="primary"
          size="large"
        >
          {isChatOpen ? 'Close Chat' : 'Start Chatting Now'}
        </Button>
      </Box>
      <Collapse in={isChatOpen} timeout="auto" unmountOnExit>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <ChatBot />
        </Box>
      </Collapse>
    </Box>
  );
};

export default CallToAction1;