import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const pages = [
  { name: "What's in my water?", path: '/test-your-water' },
  { name: 'Our Process', path: '/process' },
  { name: 'Packages', path: '/packages' },
];

function Header() { 
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const HamburgerButton = () => (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '24px',
        height: '20px',
        '& span': {
          display: 'block',
          height: '2px',
          width: '100%',
          backgroundColor: isHomePage ? 'white' : 'black',
          transition: 'all 0.2s ease',
        }
      }}
    >
      <span />
      <span />
      <span />
    </Box>
  );

  const CloseButton = () => (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        cursor: 'pointer',
        width: '24px',
        height: '24px',
        position: 'relative',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: '24px',
          height: '2px',
          backgroundColor: isHomePage ? 'white' : 'black',
          top: '50%',
          left: '0',
        },
        '&::before': {
          transform: 'rotate(45deg)',
        },
        '&::after': {
          transform: 'rotate(-45deg)',
        }
      }}
    />
  );

  const mobileMenu = (
    <Drawer
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          width: '250px',
          backgroundColor: isHomePage ? '#4A8AB5' : 'white',
          padding: '20px',
        },
      }}
    >
      <Box sx={{ textAlign: 'right', mb: 2, mr: 1 }}>
        <CloseButton />
      </Box>
      <List>
        {pages.map((page) => (
          <ListItem 
            key={page.name}
            component={Link}
            to={page.path}
            onClick={handleDrawerToggle}
            sx={{ 
              color: isHomePage ? 'white' : 'black',
              '&:hover': {
                backgroundColor: isHomePage ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
        <ListItem sx={{ mt: 2 }}>
          <Button
            component={Link}
            to="/get-a-quote"
            variant="contained"
            fullWidth
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: '#5B9AC7',
              color: 'white',
              borderRadius: '28px',
              padding: '10px 20px',
              textTransform: 'none',
              fontWeight: 'bold',
              border: '2px solid white',
              boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11)',
              '&:hover': {
                backgroundColor: '#4A8AB5',
                transform: 'translateY(-1px)',
              },
            }}
          >
            Get a quote
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ 
        padding: { xs: '12px 16px', md: '12px 24px' },
        justifyContent: 'space-between'
      }}>
        <Link to="/">
          <img 
            src="/MWPlogo.png" 
            alt="MWP Logo" 
            style={{ 
              height: isMobile ? '70px' : '105px',
              cursor: 'pointer' 
            }} 
          />
        </Link>
        
        {isMobile ? (
          <HamburgerButton />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                sx={{ 
                  color: isHomePage ? 'white' : 'black',
                  mx: 1,
                  '&:hover': {
                    color: isHomePage ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                  },
                }}
              >
                {page.name}
              </Button>
            ))}
            <Button
              component={Link}
              to="/get-a-quote"
              variant="contained"
              sx={{
                ml: 2,
                backgroundColor: '#5B9AC7',
                color: 'white',
                borderRadius: '28px',
                padding: '10px 20px',
                textTransform: 'none',
                fontWeight: 'bold',
                border: '2px solid white',
                boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11)',
                transition: 'all 0.15s ease',
                '&:hover': {
                  backgroundColor: '#4A8AB5',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1)',
                },
              }}
            >
              Get a quote
            </Button>
          </Box>
        )}
        {mobileMenu}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
