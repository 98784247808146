import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Header from './Header';
import ChatBot from './ChatBot';
import Footer from './Footer';

const ChatbotPage = () => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Find Your Perfect Water Filter
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Chat with our AI assistant to discover the best solution for you
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ChatBot />
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ChatbotPage;