import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Container, Stack } from '@mui/material';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: '10px 20px',
  fontWeight: 500,
  textTransform: 'none',
  width: 'fit-content',
  cursor: 'pointer',
  position: 'relative',
  zIndex: 10,
}));

function CallToAction() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    console.log(`Navigating to: ${path}`);
    navigate(path);
  };

  return (
    <Box sx={{ 
      bgcolor: '#e6f7ff', 
      color: '#005a8c', 
      py: 8, 
      position: 'relative',
      overflow: 'hidden',
      zIndex: 5,
    }}>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 500, color: '#005a8c' }}>
          Ready to say goodbye to tap water?
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 4, fontSize: '1.1rem', color: '#555555' }}>
          We're here to simplify your journey to cleaner, healthier water.
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
          <StyledButton 
            variant="contained" 
            size="large"
            startIcon={<WaterDropIcon />}
            onClick={() => handleNavigate('/process')}
            sx={{
              bgcolor: '#0088cc',
              '&:hover': {
                bgcolor: '#005a8c',
              },
            }}
          >
            Get Started
          </StyledButton>
          <StyledButton 
            variant="outlined" 
            size="large"
            startIcon={<FormatQuoteIcon />}
            onClick={() => handleNavigate('/testimonials')}
            sx={{
              color: '#0088cc',
              borderColor: '#0088cc',
              '&:hover': {
                bgcolor: 'rgba(0, 136, 204, 0.1)',
                borderColor: '#005a8c',
              },
            }}
          >
            Read Testimonials
          </StyledButton>
        </Stack>
      </Container>
      {/* Water drop background effect */}
      <Box sx={{
        position: 'absolute',
        top: -20,
        left: -20,
        right: -20,
        bottom: -20,
        opacity: 0.1,
        zIndex: 0,
        backgroundImage: 'radial-gradient(circle, #0088cc 8%, transparent 8%)',
        backgroundSize: '30px 30px',
      }} />
    </Box>
  );
}

export default CallToAction;
