import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Header from './Header';
import Testimonials from './Testimonials';
import Footer from './Footer';

const TestimonialsPage = () => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h2" component="h1" gutterBottom>
            What Our Customers Say
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Read testimonials from satisfied users of our water filtration solutions
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Testimonials />
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default TestimonialsPage;