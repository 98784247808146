import React from 'react';
import { Box, Typography, Paper, Grid, useTheme, Divider, useMediaQuery } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import PaidIcon from '@mui/icons-material/Paid';
import SpeedIcon from '@mui/icons-material/Speed';

const steps = [
  {
    icon: <ChatIcon fontSize="large" />,
    label: "Explore Filter Options",
    description: "Chat with experts about your specific water filtration requirements."
  },
  {
    icon: <PaidIcon fontSize="large" />,
    label: "Choose A Package",
    description: "Get the exact filter system that meets your needs, with no hidden fees."
  },
  {
    icon: <SpeedIcon fontSize="large" />,
    label: "Quick Installation",
    description: "Enjoy fast, professional setup by certified local experts."
  }
];

function Process2() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: isMobile ? 2 : 4, 
        borderRadius: 4, 
        mb: isMobile ? 4 : 8 
      }}
    >
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        align="center" 
        gutterBottom 
        sx={{ 
          mb: isMobile ? 2 : 4, 
          fontWeight: 'bold' 
        }}
      >
        Our Process
      </Typography>
      <Grid container spacing={isMobile ? 2 : 4}>
        {steps.map((step, index) => (
          <Grid item xs={12} md={4} key={step.label}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'column',
                alignItems: isMobile ? 'flex-start' : 'center',
                textAlign: isMobile ? 'left' : 'center',
                py: isMobile ? 1 : 2,
              }}
            >
              <Box
                sx={{
                  mr: isMobile ? 2 : 0,
                  mb: isMobile ? 0 : 2,
                  color: theme.palette.primary.main,
                  minWidth: isMobile ? 'auto' : undefined,
                }}
              >
                {React.cloneElement(step.icon, { 
                  sx: { fontSize: isMobile ? 40 : 64 } 
                })}
              </Box>
              <Box>
                <Typography 
                  variant={isMobile ? "subtitle1" : "h6"} 
                  sx={{ 
                    fontWeight: 'bold', 
                    mb: 0.5 
                  }}
                >
                  {step.label}
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{
                    fontSize: isMobile ? '0.875rem' : 'inherit'
                  }}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
            {isMobile && index < steps.length - 1 && (
              <Divider sx={{ my: 1 }} />
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default Process2;