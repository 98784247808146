import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Rating, Grid } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    name: 'John Doe',
    role: 'Homeowner',
    content: 'The water filtration system has made a noticeable difference in our water quality. It tastes great and we feel safer.',
    rating: 5,
    image: 'https://example.com/john-doe.jpg', // Add actual image URLs
  },
  {
    name: 'Jane Smith',
    role: 'Environmental Scientist',
    content: "As a professional in the field, I'm impressed with the effectiveness and efficiency of these filtration systems.",
    rating: 5,
    image: 'https://example.com/jane-smith.jpg',
  },
  {
    name: 'Mike Johnson',
    role: 'Restaurant Owner',
    content: 'Installing this system in our restaurant has improved our water quality and our customers have noticed!',
    rating: 4,
    image: 'https://example.com/mike-johnson.jpg',
  },
  // Add more testimonials here (if wanted)
];

const TestimonialCard = ({ testimonial }) => (
  <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 4, position: 'relative', overflow: 'visible' }}>
    <CardContent sx={{ flexGrow: 1, pt: 6, pb: 4, px: 4 }}>
      <FormatQuoteIcon sx={{ fontSize: 60, color: 'primary.main', position: 'absolute', top: -30, left: 20 }} />
      <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', fontStyle: 'italic', mb: 3 }}>
        "{testimonial.content}"
      </Typography>
      <Box sx={{ mt: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={testimonial.image}
            sx={{ width: 60, height: 60, mr: 2, border: 2, borderColor: 'primary.main' }}
          >
            {!testimonial.image && testimonial.name[0]}
          </Avatar>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{testimonial.name}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {testimonial.role}
            </Typography>
            <Rating value={testimonial.rating} readOnly size="small" />
          </Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const Testimonials = () => {
  return (
    <Box sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={index}>
            <TestimonialCard testimonial={testimonial} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;